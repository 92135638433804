import React from 'react';
import Link from '../Link';
import Img from 'gatsby-image';

const Project = ({ data }) => {
  const { id, title, imageFluid, description, highlight, name } = data;

  return (
    <div className={`flex flex-col group ${highlight ? 'md:col-span-2' : ' '} w-full`}>
      <Link to={`/projects/${id}`} className='hover:brightness-95'>
        <picture className='transition duration-500 ease-in-out w-full'>
          <img
            alt={`illustration`}
            style={{ maxHeight: 600 }}
            className='w-full object-cover hover:opacity-75 border-16 border-gray-700 rounded-xl'
            src={require(`../../images/projects/${data.thumbnail}`)}
          />
        </picture>
        <h3 className='text-white font-semibold	text-2xl mt-4 ml-4'>{title}</h3>
        <p className='text-white mt-2 ml-4'>{description}</p>
      </Link>
    </div>
  );
};

export default Project;
