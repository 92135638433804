import React from 'react';
//import projects from "./projects";
import Project from './Project';

const ProjectsContainer = ({ projects }) => {
  return (
    <div className='grid gap-y-8 gap-x-16 grid-flow-row-dense sm:grid-cols-2'>
      {projects && projects.map(project => <Project data={project} key={project.id} />)}
    </div>
  );
};

export default ProjectsContainer;
