import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from '../../components/Layout';
import SimpleBanner from '../../components/SimpleBanner';
import Seo from '../../components/Seo';
import ProjectsIllustration from '../../../assets/illustrations/projects-illustration.svg';
import TechIllustration from '../../../assets/illustrations/tech-preview.svg';
import { useTranslation } from 'react-i18next';
import withTranslations from '../../components/hoc/withTranslations';
import ProjectsContainer from '../../components/projects/ProjectsContainer';

let dataES = require('../../components/projects/projectsES.json');
let dataEN = require('../../components/projects/projectsEN.json');

const Projects = ({ pageContext }) => {
  const { t } = useTranslation();

  console.log(pageContext);
  let data = useStaticQuery(graphql`
    query {
      espaciosSeguros: file(relativePath: { eq: "projects/Finits.png" }) {
        childImageSharp {
          fluid(maxWidth: 1800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      appPacientes: file(relativePath: { eq: "projects/Gymsys.png" }) {
        childImageSharp {
          fluid(maxWidth: 1800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      appDoctores: file(relativePath: { eq: "projects/toldos.png" }) {
        childImageSharp {
          fluid(maxWidth: 1800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      appAforo: file(relativePath: { eq: "projects/vazcor.png" }) {
        childImageSharp {
          fluid(maxWidth: 1800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      paylink: file(relativePath: { eq: "projects/Haztuhorario.png" }) {
        childImageSharp {
          fluid(maxWidth: 1800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      cargo: file(relativePath: { eq: "projects/Beatbanks.png" }) {
        childImageSharp {
          fluid(maxWidth: 1800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  // TODO: translate projects data to english
  let projects = pageContext.locale === 'es' ? dataES : dataEN;

  return (
    <Layout section='PROJECTS'>
      <Seo
        title={t('projects.seoTitle')}
        description={t('projects.seoDescription')}
        keywords={['proyectos', 'haztuhorario', 'toldos', 'vazcor', 'projects', 'gymsys']}
      />

      <div className='global-margin mt-16 mb-8'>
        <h1>{t('projects.banner.title')}</h1>
        <p>{t('projects.banner.description')}</p>
      </div>

      <div className='global-margin'>
        <ProjectsContainer projects={projects} />
      </div>

      <SimpleBanner
        name={t('projects.simpleBanner.title')}
        description={t('projects.simpleBanner.description')}
        Illustration={TechIllustration}
        buttonText={t('projects.simpleBanner.button')}
        gotoRoute='/technologies'
        imgWidth='300px'
      />
    </Layout>
  );
};

export default withTranslations()(Projects);
